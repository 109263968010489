import React, { CSSProperties, Fragment, ReactNode, useEffect, useState } from 'react';

import { Avatar, Button, Card, Col, List, Popover, Progress, Row, Typography } from 'antd';

import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SettingOutlined, SnippetsOutlined } from '@ant-design/icons';
import Content from 'components/_Common/_Layout/Content';
import { AuthRoute, AuthRoutes } from 'components/_Common/_Routes/Routes';
import { PendingInvoicesModal } from 'components/CobreFacil/PendingInvoicesModal';
import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import { getUserInitials } from 'lib/helpers/Issue.helper';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { Indicator } from 'lib/Indicator';
import { OverviewContextProvider } from 'lib/providers/OverviewContextProvider';
import { listInvoices } from 'services/CobreFacil.service';
import { ActivitiesForToday } from 'services/contracts/Overview.contract';
import { findClientManagerStep, listSteps } from 'services/Setup.service';

const wl = makeWhiteLabel();
const style: CSSProperties = { color: wl.colorSecondary };

export function Overview() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState<Setup.Step[]>([]);
    const [invoices, setInvoices] = useState<CobreFacil.Invoice[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [progress, setProgress] = useState<ReactNode | null>(null);

    const userName = Authentication.getUserName();
    const clientManager = Authentication.getClientManager();

    /** @factory */
    const makeUrlWithParameter = (route: AuthRoute, parameter: string, value: string): string => {
        const url = `${route}?${parameter}=${value}`;

        return url;
    };

    const makeActions = (item: ActivitiesForToday.Data) => {
        if (item.model === 'issue')
            return <Button size='small' href={makeUrlWithParameter(AuthRoutes.overviewIssue, 'detail', item.id.toString())} target='_blank'>
                Detalhes
            </Button>;

        return <Button size='small' href={makeUrlWithParameter(AuthRoutes.overviewMaintenance, 'finish', item.id.toString())} target='_blank'>
            Finalizar
        </Button>;
    };


    const fetchSteps = async () => {
        setLoading(true);

        const response = await listSteps();

        if (!response.success)
            return;

        setSteps(response.steps);
        setLoading(false);
    };

    const renderStepProgress = async () => {
        const promises = steps.map(async (step) => {
            const stepClientManager = await findClientManagerStep(step.id);

            if (!stepClientManager.success)
                return;

            if (stepClientManager.client_manager_step === null)
                return <Typography.Paragraph>
                    <Typography.Text strong>{step.name}</Typography.Text>
                    <Progress percent={0} />
                </Typography.Paragraph>;

            if (stepClientManager.client_manager_step.status === 'in_progress' || stepClientManager.client_manager_step.status === 'pending')
                return <Typography.Paragraph>
                    <Typography.Text strong>{step.name}</Typography.Text>
                    <Progress percent={50} />
                </Typography.Paragraph>;

            if (stepClientManager.client_manager_step.status === 'finished')
                return <Typography.Paragraph>
                    <Typography.Text strong>{step.name}</Typography.Text>
                    <Progress percent={100} />
                </Typography.Paragraph>;
        });

        const results = await Promise.all(promises);

        setProgress(<span>{results.filter(result => result !== null).map(result => result)}</span>);

    };

    const renderActivitiesList = (data: ActivitiesForToday.Data[], title: string) => {
        return <Fragment>
            <Typography.Title style={{ marginTop: 0 }} level={5}>{title}</Typography.Title>
            <List
                style={{ marginBottom: 10 }}
                size='small'
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                    item.model === 'issue'
                        ? <Popover
                            content={item.lastest_follow_up?.description
                                ? <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastest_follow_up?.description }}></div>
                                : ''}
                            title={item.lastest_follow_up
                                ? `${item.lastest_follow_up?.user.name} em ${dayjs(item.lastest_follow_up?.created_at).format('DD/MM/YYYY HH:mm')}`
                                : 'Nenhuma atualização'}>
                            <List.Item actions={[makeActions(item)]}>
                                <List.Item.Meta
                                    avatar={
                                        item.responsible
                                            ? <Avatar size={'large'} src={item.picture} style={{ backgroundColor: wl.colorPrimary }}>
                                                {getUserInitials(item.responsible)}
                                            </Avatar>
                                            : <Avatar size={'large'} icon={<SnippetsOutlined />} style={{ backgroundColor: wl.colorPrimary }} />
                                    }
                                    title={item.title}
                                    description={
                                        <Fragment>
                                            <strong>{dayjs(item.deadline).format('DD/MM/YYYY')}</strong><br />
                                            {item.description}
                                        </Fragment>
                                    }
                                />
                            </List.Item>
                        </Popover>

                        : <List.Item actions={[makeActions(item)]}>
                            <List.Item.Meta
                                avatar={
                                    item.model === 'maintenance'
                                        ? <Avatar size={'large'} icon={<SettingOutlined />} style={{ backgroundColor: wl.colorPrimary }} />
                                        : item.responsible
                                            ? <Avatar size={'large'} src={item.picture} style={{ backgroundColor: wl.colorPrimary }}>
                                                {getUserInitials(item.responsible)}
                                            </Avatar>
                                            : <Avatar size={'large'} icon={<SnippetsOutlined />} style={{ backgroundColor: wl.colorPrimary }} />
                                }
                                title={item.title}
                                description={
                                    <Fragment>
                                        <strong>{dayjs(item.deadline).format('DD/MM/YYYY')}</strong><br />
                                        {item.description}
                                    </Fragment>
                                }
                            />
                        </List.Item>
                )}
            />
        </Fragment>;
    };

    const fetchInvoices = async () => {
        const response = await listInvoices(clientManager.cobre_facil_id);

        if (!response.success)
            return;

        setInvoices(response.invoices);
    };

    useEffect(() => {
        fetchSteps();
        fetchInvoices();
    }, []);

    useEffect(() => {
        renderStepProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps]);

    return (
        <OverviewContextProvider>
            {({ loadings, generalStatistics, activitiesForToday, userIssues }) => (
                <Content>
                    <Row>
                        <Col span={24}>
                            <Typography.Title level={3} style={{ marginBottom: 0 }}>
                                Bem vindo, {userName}!
                            </Typography.Title>

                            <Typography.Text type="secondary">
                                Somos a <b style={style}>evolução</b> da gestão condominial
                            </Typography.Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col xs={24} sm={24} md={8} lg={8}>
                                    <Indicator
                                        loading={loadings.includes('indicators')}
                                        iconBackgroundColor='#52c41a'
                                        icon={<CheckCircleOutlined />}
                                        label='Chamados finalizados'
                                        value={generalStatistics.total_closed_issues} />
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8}>
                                    <Indicator
                                        loading={loadings.includes('indicators')}
                                        iconBackgroundColor='#ff4d4f'
                                        icon={<ClockCircleOutlined />}
                                        label='Chamados atrasados'
                                        value={generalStatistics.total_overdue_issues} />
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8}>
                                    <Indicator
                                        loading={loadings.includes('indicators')}
                                        iconBackgroundColor='#d48806'
                                        icon={<CloseCircleOutlined />}
                                        label='Chamados pendentes'
                                        value={generalStatistics.total_pending_issues} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={8} lg={8}>
                                    <Card
                                        loading={loadings.includes('user_issues')}
                                        title="Seus chamados com prazo definido" styles={{
                                            header: {
                                                background: wl.colorPrimary,
                                                color: '#fff'
                                            }
                                        }}>
                                        <List
                                            size='small'
                                            itemLayout="horizontal"
                                            dataSource={userIssues}
                                            renderItem={(item) => (
                                                <Popover
                                                    content={item.lastest_follow_up?.description
                                                        ? <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.lastest_follow_up?.description }}></div>
                                                        : ''}
                                                    title={item.lastest_follow_up
                                                        ? `${item.lastest_follow_up?.user.name} em ${dayjs(item.lastest_follow_up?.created_at).format('DD/MM/YYYY HH:mm')}`
                                                        : 'Nenhuma atualização'}>
                                                    <List.Item actions={[
                                                        <Button size='small' href={makeUrlWithParameter(AuthRoutes.overviewIssue, 'detail', item.id.toString())} target='_blank'>
                                                            Detalhes
                                                        </Button>
                                                    ]}>
                                                        <List.Item.Meta
                                                            avatar={
                                                                item.is_overdue
                                                                    ? <Avatar size='large' icon={<ClockCircleOutlined />} style={{ backgroundColor: '#ff4d4f' }} />
                                                                    : <Avatar size='large' icon={<CloseCircleOutlined />} style={{ backgroundColor: '#d48806' }} />
                                                            }
                                                            title={item.title}
                                                            description={
                                                                <Fragment>
                                                                    <strong style={{ color: item.is_overdue ? '#ff4d4f' : '#d48806' }}>{item.deadline_description}</strong><br />
                                                                    {item.description}
                                                                </Fragment>
                                                            }
                                                        />
                                                    </List.Item>
                                                </Popover>
                                            )}
                                        />
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={16} lg={16}>
                                    <Card loading={loadings.includes('activities')} title="Atividades atrasadas e que vencem nesta semana" styles={{
                                        header: {
                                            background: wl.colorPrimary,
                                            color: '#fff'
                                        }
                                    }}>
                                        {renderActivitiesList(activitiesForToday.filter(item => dayjs(item.deadline).startOf('day').isBefore(dayjs().startOf('day'))), 'Atrasadas')}
                                        {renderActivitiesList(activitiesForToday.filter(item => dayjs(item.deadline).startOf('day').isSame(dayjs().startOf('day'))), 'Vencem hoje')}
                                        {renderActivitiesList(activitiesForToday.filter(item => dayjs(item.deadline).startOf('day').isAfter(dayjs().startOf('day'))), 'Próximos dias')}
                                    </Card>
                                </Col>
                                {/* <Col span={6}>
                                        <Card title="Atualizações recentes" styles={{
                                            header: {
                                                background: wl.colorPrimary,
                                                color: '#fff'
                                            }
                                        }}>
                                            <List
                                                size='small'
                                                itemLayout="horizontal"
                                                dataSource={data}
                                                renderItem={(item, index) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={<Avatar size={'large'} src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                                                            title={<a href="https://ant.design">{item.title}</a>}
                                                            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </Card>
                                    </Col> */}
                            </Row>
                        </Col>
                        {/* <Col span={8}>
                                <Row>
                                    <Col span={24}>
                                        <Card
                                            actions={[
                                                <Typography.Link>
                                                    <Link to={'/setup'}><SettingOutlined /> Configurar minha empresa</Link>
                                                </Typography.Link>
                                            ]}
                                            title="Processo de implantação"
                                            loading={loading}>
                                            <Typography.Paragraph>
                                                Para usufruir ao máximo do nosso sistema, complete as 4 etapas do nosso módulo de setup:
                                            </Typography.Paragraph>
                                            {progress}
                                            <Typography.Paragraph>
                                                Complete cada etapa para maximizar o uso do nosso sistema e alcançar os melhores resultados!
                                            </Typography.Paragraph>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col> */}
                    </Row>
                    <PendingInvoicesModal invoices={invoices} />
                </Content>
            )}
        </OverviewContextProvider>
    );
}
